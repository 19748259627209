import { ReactNode } from 'react';

import { Header } from '@/components/header';
import Head from 'next/head';

interface Props {
  children: ReactNode;
}

export const Main = ({ children }: Props) => {
  return (
    <>
      <div className="flex flex-col mx-auto max-w-[65ch]">
        <Header />
        <main className="px-3 md:px-0 mt-8">{children}</main>
      </div>
    </>
  );
};
