import Link from 'next/link';
import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';

export const Header = () => {
  const router = useRouter();
  const [menuIsOpen, setMenusIsOpen] = useState(false);

  useEffect(() => {
    router.events.on('routeChangeComplete', () => setMenusIsOpen(false));
    router.events.on('routeChangeError', () => setMenusIsOpen(false));

    return () => {
      router.events.off('routeChangeComplete', () => setMenusIsOpen(false));
      router.events.off('routeChangeError', () => setMenusIsOpen(false));
    };
  }, [router]);

  return (
    <header className="flex justify-between items-center py-5 border-b-2 md:items-baseline border-b-slate-200 px-3 md:px-0">
      <h1 className="p-0 m-0 text-4xl font-extrabold text-transparent lowercase bg-clip-text bg-gradient-to-r from-rose-400 via-indigo-500 to-purple-600 md:inline">
        <Link href="/">
          <a>chris bailey</a>
        </Link>
      </h1>
      <button
        className="flex relative z-50 items-center mr-2 space-x-2 w-6 h-6 lg:hidden focus:outline-none"
        onClick={() => setMenusIsOpen(!menuIsOpen)}
      >
        <div className="flex relative justify-center items-center w-6">
          <span
            className={`absolute w-full h-[2px] bg-current transition transform ${
              menuIsOpen ? 'translate-y-0 rotate-45' : '-translate-y-2'
            }`}
          ></span>

          <span
            className={`absolute w-full h-[2px] bg-current transition transform ${
              menuIsOpen ? 'opacity-0 translate-x-3' : 'opacity-100'
            }`}
          ></span>

          <span
            className={`absolute w-full h-[2px] bg-current transition transform ${
              menuIsOpen ? 'translate-y-0 -rotate-45' : 'translate-y-2'
            }`}
          ></span>
        </div>
      </button>
      <nav
        className={`${
          menuIsOpen ? 'grid' : 'invisible'
        } absolute top-0 left-0 z-40 md:visible md:block place-items-center w-screen h-screen bg-white md:relative md:top-auto md:left-auto md:w-auto md:h-auto md:bg-transparent`}
      >
        <ul className="flex flex-col gap-3 justify-center md:flex-row">
          <li>
            <Link href="/">
              <a className="text-2xl md:text-lg link__gradient">home</a>
            </Link>
          </li>
          <li>
            <Link href="/writing">
              <a className="text-2xl md:text-lg link__gradient">writing</a>
            </Link>
          </li>
          <li>
            <Link href="/projects">
              <a className="text-2xl md:text-lg link__gradient">projects</a>
            </Link>
          </li>
          <li>
            <Link href="/about">
              <a className="text-2xl md:text-lg link__gradient">about</a>
            </Link>
          </li>
        </ul>
      </nav>
    </header>
  );
};
