import Head from 'next/head';
import Link from 'next/link';

import { Main } from '@/layouts/main';
import sanityClient from '@/lib/sanityClient';
import { postsQuery } from '@/lib/sanityQueries';

import type { Post } from '@/types';
import type { NextPage } from 'next';

interface Props {
  posts: Post[];
}

const Home: NextPage<Props> = ({ posts }) => {
  return (
    <>
      <Head>
        <title>chrisbailey.dev</title>
        <meta
          name="description"
          content="personal blog and portfolio site of chris bailey. a full stack(ish) web developer out of michigan"
        />
      </Head>
      <Main>
        <section>
          <h2 className="text-3xl font-extrabold text-indigo-500 lowercase">
            Recent Posts
          </h2>
          <div className="grid grid-cols-1 gap-4 mt-3 md:grid-cols-2">
            {posts.map((post) => (
              <article
                key={post._id}
                className="flex flex-col border-b-2 transition-all duration-300 ease-in-out border-b-gray-200 hover:border-b-slate-500"
              >
                <Link href={`/post/${post.slug.current}`}>
                  <a className="text-xl font-bold transition-all duration-300 ease-in-out hover:text-rose-600">
                    <h3>{post.title}</h3>
                  </a>
                </Link>
                <p className="my-[.5em]">{post.description}</p>
              </article>
            ))}
          </div>
        </section>
      </Main>
    </>
  );
};

export async function getStaticProps() {
  const posts: Post[] = await sanityClient.fetch(postsQuery);
  return {
    props: { posts: posts.slice(0, 2) }
  };
}

export default Home;
